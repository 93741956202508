<template>
    <div>
        <ul class="flex space-x-2 rtl:space-x-reverse">
            <li>
                <a href="javascript:;" class="text-primary hover:underline">Dashboard</a>
            </li>
            <li class="before:content-['/'] before:mr-2 rtl:before:ml-2">
                <span>Analytics</span>
            </li>
        </ul>
        <div class="pt-5">
            <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                <div class="panel h-full sm:col-span-2 lg:col-span-1">
                    <!-- statistics -->
                    <div class="flex items-center justify-between dark:text-white-light mb-5">
                        <h5 class="font-semibold text-lg">Statistics</h5>
                        <div class="dropdown">
                            <Popper :placement="store.rtlClass === 'rtl' ? 'bottom-start' : 'bottom-end'" offsetDistance="0" class="align-middle">
                                <button type="button">
                                    <icon-horizontal-dots class="text-black/70 dark:text-white/70 hover:!text-primary" />
                                </button>
                                <template #content="{ close }">
                                    <ul @click="close()">
                                        <li>
                                            <a href="javascript:;">This Week</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Last Week</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">This Month</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Last Month</a>
                                        </li>
                                    </ul>
                                </template>
                            </Popper>
                        </div>
                    </div>
                    <div class="grid sm:grid-cols-2 gap-8 text-sm text-[#515365] font-bold">
                        <div>
                            <div>
                                <div>Total Visits</div>
                                <div class="text-[#f8538d] text-lg">423,964</div>
                            </div>
                            <apexchart height="58" :options="totalVisit" :series="totalVisitSeries" class="overflow-hidden">
                                <!-- loader -->
                                <div class="min-h-[58px] grid place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                                    <span
                                        class="animate-spin border-2 border-black dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"
                                    ></span>
                                </div>
                            </apexchart>
                        </div>

                        <div>
                            <div>
                                <div>Paid Visits</div>
                                <div class="text-[#f8538d] text-lg">7,929</div>
                            </div>
                            <apexchart height="58" :options="paidVisit" :series="paidVisitSeries" class="overflow-hidden">
                                <!-- loader -->
                                <div class="min-h-[58px] grid place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                                    <span
                                        class="animate-spin border-2 border-black dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"
                                    ></span>
                                </div>
                            </apexchart>
                        </div>
                    </div>
                </div>

                <div class="panel h-full">
                    <div class="flex items-center justify-between dark:text-white-light mb-5">
                        <h5 class="font-semibold text-lg">Expenses</h5>
                        <div class="dropdown">
                            <Popper :placement="store.rtlClass === 'rtl' ? 'bottom-start' : 'bottom-end'" offsetDistance="0" class="align-middle">
                                <button type="button">
                                    <icon-horizontal-dots class="text-black/70 dark:text-white/70 hover:!text-primary" />
                                </button>
                                <template #content="{ close }">
                                    <ul @click="close()">
                                        <li>
                                            <a href="javascript:;">This Week</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Last Week</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">This Month</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Last Month</a>
                                        </li>
                                    </ul>
                                </template>
                            </Popper>
                        </div>
                    </div>
                    <div class="text-[#e95f2b] text-3xl font-bold my-10">
                        <span class="ltr:mr-2 rtl:ml-2">$ 45,141</span>
                        <span class="text-black text-sm dark:text-white-light ltr:mr-1 rtl:ml-1">this week </span>
                        <icon-trending-up class="text-success inline" />
                    </div>
                    <div class="flex items-center justify-between">
                        <div class="w-full rounded-full h-5 p-1 bg-dark-light overflow-hidden shadow-3xl dark:shadow-none dark:bg-dark-light/10">
                            <div
                                class="bg-gradient-to-r from-[#4361ee] to-[#805dca] w-full h-full rounded-full relative before:absolute before:inset-y-0 ltr:before:right-0.5 rtl:before:left-0.5 before:bg-white before:w-2 before:h-2 before:rounded-full before:m-auto"
                                style="width: 65%"
                            ></div>
                        </div>
                        <span class="ltr:ml-5 rtl:mr-5 dark:text-white-light">57%</span>
                    </div>
                </div>

                <div
                    class="panel h-full overflow-hidden before:bg-[#1937cc] before:absolute before:-right-44 before:top-0 before:bottom-0 before:m-auto before:rounded-full before:w-96 before:h-96 grid grid-cols-1 content-between"
                    style="background: linear-gradient(0deg, #00c6fb -227%, #005bea) !important"
                >
                    <div class="flex items-start justify-between text-white-light mb-16 z-[7]">
                        <h5 class="font-semibold text-lg">Total Balance</h5>

                        <div class="relative text-xl whitespace-nowrap">
                            $ 41,741.42
                            <span class="table text-[#d3d3d3] bg-[#4361ee] rounded p-1 text-xs mt-1 ltr:ml-auto rtl:mr-auto">+ 2453</span>
                        </div>
                    </div>
                    <div class="flex items-center justify-between z-10">
                        <div class="flex items-center justify-between">
                            <a
                                href="javascript:;"
                                class="shadow-[0_0_2px_0_#bfc9d4] rounded p-1 text-white-light hover:bg-[#1937cc] place-content-center ltr:mr-2 rtl:ml-2"
                            >
                                <icon-plus />
                            </a>
                            <a href="javascript:;" class="shadow-[0_0_2px_0_#bfc9d4] rounded p-1 text-white-light hover:bg-[#1937cc] grid place-content-center">
                                <icon-credit-card />
                            </a>
                        </div>
                        <a href="javascript:;" class="shadow-[0_0_2px_0_#bfc9d4] rounded p-1 text-white-light hover:bg-[#4361ee] z-10"> Upgrade </a>
                    </div>
                </div>
            </div>

            <div class="grid lg:grid-cols-3 gap-6 mb-6">
                <div class="panel h-full p-0 lg:col-span-2">
                    <div class="flex items-start justify-between dark:text-white-light mb-5 p-5 border-b border-[#e0e6ed] dark:border-[#1b2e4b]">
                        <h5 class="font-semibold text-lg">Unique Visitors</h5>
                        <div class="dropdown">
                            <Popper :placement="store.rtlClass === 'rtl' ? 'bottom-start' : 'bottom-end'" offsetDistance="0" class="align-middle">
                                <button type="button">
                                    <icon-horizontal-dots class="text-black/70 dark:text-white/70 hover:!text-primary" />
                                </button>
                                <template #content="{ close }">
                                    <ul @click="close()">
                                        <li>
                                            <a href="javascript:;">View</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Update</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Download</a>
                                        </li>
                                    </ul>
                                </template>
                            </Popper>
                        </div>
                    </div>

                    <apexchart height="360" :options="uniqueVisitor" :series="uniqueVisitorSeries" class="overflow-hidden">
                        <!-- loader -->
                        <div class="min-h-[360px] grid place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                            <span class="animate-spin border-2 border-black dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"></span>
                        </div>
                    </apexchart>
                </div>

                <div class="panel h-full">
                    <div class="flex items-start justify-between dark:text-white-light mb-5 -mx-5 p-5 pt-0 border-b border-[#e0e6ed] dark:border-[#1b2e4b]">
                        <h5 class="font-semibold text-lg">Activity Log</h5>
                        <div class="dropdown">
                            <Popper :placement="store.rtlClass === 'rtl' ? 'bottom-start' : 'bottom-end'" offsetDistance="0" class="align-middle">
                                <button type="button">
                                    <icon-horizontal-dots class="text-black/70 dark:text-white/70 hover:!text-primary" />
                                </button>
                                <template #content="{ close }">
                                    <ul @click="close()">
                                        <li>
                                            <a href="javascript:;">View All</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Mark as Read</a>
                                        </li>
                                    </ul>
                                </template>
                            </Popper>
                        </div>
                    </div>
                    <perfect-scrollbar
                        :options="{
                            swipeEasing: true,
                            wheelPropagation: false,
                        }"
                        class="relative h-[360px] ltr:pr-3 rtl:pl-3 ltr:-mr-3 rtl:-ml-3"
                    >
                        <div class="space-y-7">
                            <div class="flex">
                                <div
                                    class="shrink-0 ltr:mr-2 rtl:ml-2 relative z-10 before:w-[2px] before:h-[calc(100%-24px)] before:bg-white-dark/30 before:absolute before:top-10 before:left-4"
                                >
                                    <div class="bg-secondary shadow shadow-secondary w-8 h-8 rounded-full flex items-center justify-center text-white">
                                        <icon-plus class="w-4 h-4" />
                                    </div>
                                </div>
                                <div>
                                    <h5 class="font-semibold dark:text-white-light">
                                        New project created : <a href="javascript:;" class="text-success">[VRISTO Admin Template]</a>
                                    </h5>
                                    <p class="text-white-dark text-xs">27 Feb, 2020</p>
                                </div>
                            </div>
                            <div class="flex">
                                <div
                                    class="shrink-0 ltr:mr-2 rtl:ml-2 relative z-10 before:w-[2px] before:h-[calc(100%-24px)] before:bg-white-dark/30 before:absolute before:top-10 before:left-4"
                                >
                                    <div class="bg-success shadow-success w-8 h-8 rounded-full flex items-center justify-center text-white">
                                        <icon-mail class="w-4 h-4" />
                                    </div>
                                </div>
                                <div>
                                    <h5 class="font-semibold dark:text-white-light">
                                        Mail sent to <a href="javascript:;" class="text-white-dark">HR</a> and
                                        <a href="javascript:;" class="text-white-dark">Admin</a>
                                    </h5>
                                    <p class="text-white-dark text-xs">28 Feb, 2020</p>
                                </div>
                            </div>
                            <div class="flex">
                                <div
                                    class="shrink-0 ltr:mr-2 rtl:ml-2 relative z-10 before:w-[2px] before:h-[calc(100%-24px)] before:bg-white-dark/30 before:absolute before:top-10 before:left-4"
                                >
                                    <div class="bg-primary w-8 h-8 rounded-full flex items-center justify-center text-white">
                                        <icon-checks class="w-4 h-4" />
                                    </div>
                                </div>
                                <div>
                                    <h5 class="font-semibold dark:text-white-light">Server Logs Updated</h5>
                                    <p class="text-white-dark text-xs">27 Feb, 2020</p>
                                </div>
                            </div>
                            <div class="flex">
                                <div
                                    class="shrink-0 ltr:mr-2 rtl:ml-2 relative z-10 before:w-[2px] before:h-[calc(100%-24px)] before:bg-white-dark/30 before:absolute before:top-10 before:left-4"
                                >
                                    <div class="bg-danger w-8 h-8 rounded-full flex items-center justify-center text-white">
                                        <icon-checks class="w-4 h-4" />
                                    </div>
                                </div>
                                <div>
                                    <h5 class="font-semibold dark:text-white-light">
                                        Task Completed : <a href="javascript:;" class="text-success">[Backup Files EOD]</a>
                                    </h5>
                                    <p class="text-white-dark text-xs">01 Mar, 2020</p>
                                </div>
                            </div>
                            <div class="flex">
                                <div
                                    class="shrink-0 ltr:mr-2 rtl:ml-2 relative z-10 before:w-[2px] before:h-[calc(100%-24px)] before:bg-white-dark/30 before:absolute before:top-10 before:left-4"
                                >
                                    <div class="bg-warning w-8 h-8 rounded-full flex items-center justify-center text-white">
                                        <icon-file class="w-4 h-4" />
                                    </div>
                                </div>
                                <div>
                                    <h5 class="font-semibold dark:text-white-light">Documents Submitted from <a href="javascript:;">Sara</a></h5>
                                    <p class="text-white-dark text-xs">10 Mar, 2020</p>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="shrink-0 ltr:mr-2 rtl:ml-2">
                                    <div class="bg-dark w-8 h-8 rounded-full flex items-center justify-center text-white">
                                        <icon-server class="w-4 h-4" />
                                    </div>
                                </div>
                                <div>
                                    <h5 class="font-semibold dark:text-white-light">Server rebooted successfully</h5>
                                    <p class="text-white-dark text-xs">06 Apr, 2020</p>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>

            <div class="grid sm:grid-cols-3 xl:grid-cols-5 gap-6 mb-6">
                <div class="panel h-full sm:col-span-3 xl:col-span-2">
                    <div class="flex items-start justify-between mb-5">
                        <h5 class="font-semibold text-lg dark:text-white-light">Visitors by Browser</h5>
                    </div>
                    <div class="flex flex-col space-y-5">
                        <div class="flex items-center">
                            <div class="w-9 h-9">
                                <div
                                    class="bg-primary/10 text-primary rounded-xl w-9 h-9 flex justify-center items-center dark:bg-primary dark:text-white-light"
                                >
                                    <icon-chrome class="w-5 h-5" />
                                </div>
                            </div>
                            <div class="px-3 flex-initial w-full">
                                <div class="w-summary-info flex justify-between font-semibold text-white-dark mb-1">
                                    <h6>Chrome</h6>
                                    <p class="ltr:ml-auto rtl:mr-auto text-xs">65%</p>
                                </div>
                                <div>
                                    <div class="w-full rounded-full h-5 p-1 bg-dark-light overflow-hidden shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
                                        <div
                                            class="bg-gradient-to-r from-[#009ffd] to-[#2a2a72] w-full h-full rounded-full relative before:absolute before:inset-y-0 ltr:before:right-0.5 rtl:before:left-0.5 before:bg-white before:w-2 before:h-2 before:rounded-full before:m-auto"
                                            style="width: 65%"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center">
                            <div class="w-9 h-9">
                                <div class="bg-danger/10 text-danger rounded-xl w-9 h-9 flex justify-center items-center dark:bg-danger dark:text-white-light">
                                    <icon-safari class="w-5 h-5" />
                                </div>
                            </div>
                            <div class="px-3 flex-initial w-full">
                                <div class="w-summary-info flex justify-between font-semibold text-white-dark mb-1">
                                    <h6>Safari</h6>
                                    <p class="ltr:ml-auto rtl:mr-auto text-xs">40%</p>
                                </div>
                                <div>
                                    <div class="w-full rounded-full h-5 p-1 bg-dark-light overflow-hidden shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
                                        <div
                                            class="bg-gradient-to-r from-[#a71d31] to-[#3f0d12] w-full h-full rounded-full relative before:absolute before:inset-y-0 ltr:before:right-0.5 rtl:before:left-0.5 before:bg-white before:w-2 before:h-2 before:rounded-full before:m-auto"
                                            style="width: 40%"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center">
                            <div class="w-9 h-9">
                                <div
                                    class="bg-warning/10 text-warning rounded-xl w-9 h-9 flex justify-center items-center dark:bg-warning dark:text-white-light"
                                >
                                    <icon-globe class="w-5 h-5" />
                                </div>
                            </div>
                            <div class="px-3 flex-initial w-full">
                                <div class="w-summary-info flex justify-between font-semibold text-white-dark mb-1">
                                    <h6>Others</h6>
                                    <p class="ltr:ml-auto rtl:mr-auto text-xs">25%</p>
                                </div>
                                <div>
                                    <div class="w-full rounded-full h-5 p-1 bg-dark-light overflow-hidden shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
                                        <div
                                            class="bg-gradient-to-r from-[#fe5f75] to-[#fc9842] w-full h-full rounded-full relative before:absolute before:inset-y-0 ltr:before:right-0.5 rtl:before:left-0.5 before:bg-white before:w-2 before:h-2 before:rounded-full before:m-auto"
                                            style="width: 25%"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel h-full p-0">
                    <div class="flex p-5">
                        <div
                            class="shrink-0 bg-primary/10 text-primary rounded-xl w-11 h-11 flex justify-center items-center dark:bg-primary dark:text-white-light"
                        >
                            <icon-users-group class="w-5 h-5" />
                        </div>
                        <div class="ltr:ml-3 rtl:mr-3 font-semibold">
                            <p class="text-xl dark:text-white-light">31.6K</p>
                            <h5 class="text-[#506690] text-xs">Followers</h5>
                        </div>
                    </div>
                    <div class="h-40">
                        <apexchart height="160" :options="followers" :series="followersSeries" class="w-full absolute bottom-0 overflow-hidden">
                            <!-- loader -->
                            <div class="min-h-[160px] grid place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                                <span
                                    class="animate-spin border-2 border-black dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"
                                ></span>
                            </div>
                        </apexchart>
                    </div>
                </div>

                <div class="panel h-full p-0">
                    <div class="flex p-5">
                        <div
                            class="shrink-0 bg-danger/10 text-danger rounded-xl w-11 h-11 flex justify-center items-center dark:bg-danger dark:text-white-light"
                        >
                            <icon-link class="w-5 h-5" />
                        </div>
                        <div class="ltr:ml-3 rtl:mr-3 font-semibold">
                            <p class="text-xl dark:text-white-light">1,900</p>
                            <h5 class="text-[#506690] text-xs">Referral</h5>
                        </div>
                    </div>
                    <div class="h-40">
                        <apexchart height="160" :options="referral" :series="referralSeries" class="w-full absolute bottom-0 overflow-hidden">
                            <!-- loader -->
                            <div class="min-h-[160px] grid place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                                <span
                                    class="animate-spin border-2 border-black dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"
                                ></span>
                            </div>
                        </apexchart>
                    </div>
                </div>

                <div class="panel h-full p-0">
                    <div class="flex p-5">
                        <div
                            class="shrink-0 bg-success/10 text-success rounded-xl w-11 h-11 flex justify-center items-center dark:bg-success dark:text-white-light"
                        >
                            <icon-chat-dots class="w-5 h-5" />
                        </div>
                        <div class="ltr:ml-3 rtl:mr-3 font-semibold">
                            <p class="text-xl dark:text-white-light">18.2%</p>
                            <h5 class="text-[#506690] text-xs">Engagement</h5>
                        </div>
                    </div>
                    <div class="h-40">
                        <apexchart height="160" :options="engagement" :series="engagementSeries" class="w-full absolute bottom-0 overflow-hidden">
                            <!-- loader -->
                            <div class="min-h-[160px] grid place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                                <span
                                    class="animate-spin border-2 border-black dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"
                                ></span>
                            </div>
                        </apexchart>
                    </div>
                </div>
            </div>

            <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-6">
                <div class="panel h-full">
                    <div class="flex items-start border-b border-[#e0e6ed] dark:border-[#1b2e4b] -m-5 mb-5 p-5">
                        <div class="shrink-0 ring-2 ring-white-light dark:ring-dark rounded-full ltr:mr-4 rtl:ml-4">
                            <img src="/assets/images/profile-1.jpeg" alt="" class="w-10 h-10 rounded-full object-cover" />
                        </div>
                        <div class="font-semibold">
                            <h6>Jimmy Turner</h6>
                            <p class="text-xs text-white-dark mt-1">Monday, Nov 18</p>
                        </div>
                    </div>
                    <div>
                        <div class="text-white-dark pb-8">
                            "Duis aute irure dolor" in reprehenderit in voluptate velit esse cillum "dolore eu fugiat" nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident.
                        </div>
                        <div class="w-full absolute bottom-0 flex items-center justify-between p-5 -mx-5">
                            <div class="flex items-center">
                                <icon-thumb-up class="w-5 h-5 text-info inline ltr:mr-1.5 rtl:ml-1.5 relative -top-0.5" />
                                <span class="dark:text-info">551 Likes</span>
                            </div>
                            <a
                                href="javascript:;"
                                class="flex items-center bg-success/30 text-success rounded-md px-1.5 py-1 text-xs hover:shadow-[0_10px_20px_-10px] hover:shadow-success"
                                >Read More
                                <icon-carets-down class="w-4 h-4 rtl:rotate-90 -rotate-90 ltr:ml-1.5 rtl:mr-1.5" />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="panel h-full">
                    <div class="flex items-center justify-between border-b border-[#e0e6ed] dark:border-[#1b2e4b] -m-5 mb-5 p-5">
                        <div class="flex">
                            <div class="media-aside align-self-start">
                                <div class="shrink-0 ring-2 ring-white-light dark:ring-dark rounded-full ltr:mr-4 rtl:ml-4">
                                    <img src="/assets/images/g-8.png" alt="" class="w-10 h-10 rounded-full object-cover" />
                                </div>
                            </div>
                            <div class="font-semibold">
                                <h6>Dev Summit - New York</h6>
                                <p class="text-xs text-white-dark mt-1">Bronx, NY</p>
                            </div>
                        </div>
                    </div>
                    <div class="font-semibold text-center pb-8">
                        <div class="mb-4 text-primary">4 Members Going</div>
                        <div class="flex items-center justify-center gap-3 pb-8">
                            <img
                                class="w-10 h-10 ring-2 ring-white-light dark:ring-dark rounded-lg overflow-hidden object-cover"
                                src="/assets/images/profile-1.jpeg"
                                alt=""
                            />
                            <img
                                class="w-10 h-10 ring-2 ring-white-light dark:ring-dark rounded-lg overflow-hidden object-cover"
                                src="/assets/images/profile-2.jpeg"
                                alt=""
                            />
                            <img
                                class="w-10 h-10 ring-2 ring-white-light dark:ring-dark rounded-lg overflow-hidden object-cover"
                                src="/assets/images/profile-3.jpeg"
                                alt=""
                            />
                            <img
                                class="w-10 h-10 ring-2 ring-white-light dark:ring-dark rounded-lg overflow-hidden object-cover"
                                src="/assets/images/profile-4.jpeg"
                                alt=""
                            />
                        </div>

                        <div class="w-full absolute bottom-0 flex items-center justify-between p-5 -mx-5">
                            <a href="javascript:;" class="btn btn-secondary btn-lg w-full border-0 bg-gradient-to-r from-[#3d38e1] to-[#1e9afe]"
                                >View Details</a
                            >
                        </div>
                    </div>
                </div>

                <div class="panel h-full">
                    <div class="flex items-center justify-between border-b border-[#e0e6ed] dark:border-[#1b2e4b] -m-5 mb-5 p-5">
                        <a href="javascript:;" class="flex font-semibold">
                            <div class="shrink-0 bg-secondary w-10 h-10 rounded-md flex items-center justify-center text-white ltr:mr-4 rtl:ml-4">
                                <span>FD</span>
                            </div>
                            <div>
                                <h6>Figma Design</h6>
                                <p class="text-xs text-white-dark mt-1">Design Reset</p>
                            </div>
                        </a>
                        <div class="dropdown">
                            <Popper :placement="store.rtlClass === 'rtl' ? 'bottom-start' : 'bottom-end'" offsetDistance="0" class="align-middle">
                                <button type="button">
                                    <icon-horizontal-dots class="w-5 h-5 text-black/70 dark:text-white/70 hover:!text-primary" />
                                </button>
                                <template #content="{ close }">
                                    <ul @click="close()">
                                        <li>
                                            <a href="javascript:;">View Project</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Edit Project</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Mark as Done</a>
                                        </li>
                                    </ul>
                                </template>
                            </Popper>
                        </div>
                    </div>
                    <div class="group">
                        <div class="mb-5">
                            <div class="text-white-dark">
                                Doloribus nisi vel suscipit modi, optio ex repudiandae voluptatibus officiis commodi. Nesciunt quas aut neque incidunt!
                            </div>
                        </div>
                        <div class="flex items-center justify-between mb-2 font-semibold">
                            <div class="flex items-center">
                                <icon-square-check class="w-4 h-4 text-success" />

                                <div class="ltr:ml-2 rtl:mr-2 text-xs">5 Tasks</div>
                            </div>
                            <p class="text-primary">65%</p>
                        </div>
                        <div class="rounded-full h-2.5 p-0.5 bg-dark-light dark:bg-dark-light/10 mb-5">
                            <div class="bg-gradient-to-r from-[#1e9afe] to-[#60dfcd] h-full rounded-full" style="width: 65%"></div>
                        </div>
                        <div class="flex items-end justify-between">
                            <div class="flex items-center rounded-full bg-danger/20 px-2 py-1 text-xs text-danger font-semibold">
                                <icon-clock class="w-3 h-3 ltr:mr-1 rtl:ml-1" />

                                3 Days Left
                            </div>
                            <div class="flex items-center justify-center group-hover:-space-x-2 rtl:space-x-reverse rtl:group-hover:space-x-reverse">
                                <span
                                    class="bg-[#bfc9d4] dark:bg-dark w-9 h-9 rounded-full flex items-center justify-center text-white font-semibold transition-all duration-300 opacity-0 group-hover:opacity-100"
                                    >+6</span
                                >
                                <img
                                    class="w-9 h-9 border-2 border-white dark:border-dark rounded-full object-cover transition-all duration-300"
                                    src="/assets/images/profile-6.jpeg"
                                    alt=""
                                />
                                <img
                                    class="w-9 h-9 border-2 border-white dark:border-dark rounded-full object-cover transition-all duration-300"
                                    src="/assets/images/profile-7.jpeg"
                                    alt=""
                                />
                                <img
                                    class="w-9 h-9 border-2 border-white dark:border-dark rounded-full object-cover transition-all duration-300"
                                    src="/assets/images/profile-8.jpeg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import apexchart from 'vue3-apexcharts';
    import { useAppStore } from '@/stores/index';
    import { useMeta } from '@/composables/use-meta';

    import IconHorizontalDots from '@/components/icon/icon-horizontal-dots.vue';
    import IconTrendingUp from '@/components/icon/icon-trending-up.vue';
    import IconPlus from '@/components/icon/icon-plus.vue';
    import IconCreditCard from '@/components/icon/icon-credit-card.vue';
    import IconMail from '@/components/icon/icon-mail.vue';
    import IconChecks from '@/components/icon/icon-checks.vue';
    import IconFile from '@/components/icon/icon-file.vue';
    import IconServer from '@/components/icon/icon-server.vue';
    import IconChrome from '@/components/icon/icon-chrome.vue';
    import IconSafari from '@/components/icon/icon-safari.vue';
    import IconGlobe from '@/components/icon/icon-globe.vue';
    import IconUsersGroup from '@/components/icon/icon-users-group.vue';
    import IconLink from '@/components/icon/icon-link.vue';
    import IconChatDots from '@/components/icon/icon-chat-dots.vue';
    import IconThumbUp from '@/components/icon/icon-thumb-up.vue';
    import IconCaretsDown from '@/components/icon/icon-carets-down.vue';
    import IconSquareCheck from '@/components/icon/icon-square-check.vue';
    import IconClock from '@/components/icon/icon-clock.vue';

    useMeta({ title: 'Analytics Admin' });
    const store = useAppStore();

    // statistics
    const totalVisit = computed(() => {
        const isDark: boolean = store.theme === 'dark' || store.isDarkMode ? true : false;
        return {
            chart: {
                height: 58,
                type: 'line',
                fontFamily: 'Nunito, sans-serif',
                sparkline: {
                    enabled: true,
                },
                dropShadow: {
                    enabled: true,
                    blur: 3,
                    color: '#009688',
                    opacity: 0.4,
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            colors: ['#009688'],
            grid: {
                padding: {
                    top: 5,
                    bottom: 5,
                    left: 5,
                    right: 5,
                },
            },
            tooltip: {
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: (val: any) => {
                            return '';
                        },
                    },
                },
            },
        };
    });

    const totalVisitSeries = ref([
        {
            data: [21, 9, 36, 12, 44, 25, 59, 41, 66, 25],
        },
    ]);

    //paid visit
    const paidVisit = computed(() => {
        const isDark: boolean = store.theme === 'dark' || store.isDarkMode ? true : false;
        return {
            chart: {
                height: 58,
                type: 'line',
                fontFamily: 'Nunito, sans-serif',
                sparkline: {
                    enabled: true,
                },
                dropShadow: {
                    enabled: true,
                    blur: 3,
                    color: '#e2a03f',
                    opacity: 0.4,
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            colors: ['#e2a03f'],
            grid: {
                padding: {
                    top: 5,
                    bottom: 5,
                    left: 5,
                    right: 5,
                },
            },
            tooltip: {
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: (val: any) => {
                            return '';
                        },
                    },
                },
            },
        };
    });

    const paidVisitSeries = ref([
        {
            data: [22, 19, 30, 47, 32, 44, 34, 55, 41, 69],
        },
    ]);

    // unique visitors
    const uniqueVisitor = computed(() => {
        const isDark: boolean = store.theme === 'dark' || store.isDarkMode ? true : false;
        const isRtl = store.rtlClass === 'rtl' ? true : false;
        return {
            chart: {
                height: 360,
                type: 'bar',
                fontFamily: 'Nunito, sans-serif',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 2,
                colors: ['transparent'],
            },
            colors: ['#5c1ac3', '#ffbb44'],
            dropShadow: {
                enabled: true,
                blur: 3,
                color: '#515365',
                opacity: 0.4,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 10,
                },
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                itemMargin: {
                    horizontal: 8,
                    vertical: 8,
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
                padding: {
                    left: 20,
                    right: 20,
                },
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                axisBorder: {
                    show: true,
                    color: isDark ? '#3b3f5c' : '#e0e6ed',
                },
            },
            yaxis: {
                tickAmount: 6,
                opposite: isRtl ? true : false,
                labels: {
                    offsetX: isRtl ? -10 : 0,
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: isDark ? 'dark' : 'light',
                    type: 'vertical',
                    shadeIntensity: 0.3,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 100],
                },
            },
            tooltip: {
                marker: {
                    show: true,
                },
                y: {
                    formatter: (val: any) => {
                        return val;
                    },
                },
            },
        };
    });

    const uniqueVisitorSeries = ref([
        {
            name: 'Direct',
            data: [58, 44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 63],
        },
        {
            name: 'Organic',
            data: [91, 76, 85, 101, 98, 87, 105, 91, 114, 94, 66, 70],
        },
    ]);

    // followers
    const followers = computed(() => {
        const isDark: boolean = store.theme === 'dark' || store.isDarkMode ? true : false;
        return {
            chart: {
                height: 160,
                type: 'area',
                fontFamily: 'Nunito, sans-serif',
                sparkline: {
                    enabled: true,
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            colors: ['#4361ee'],
            grid: {
                padding: {
                    top: 5,
                },
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: (val: any) => {
                            return '';
                        },
                    },
                },
            },
            option: { fill: null },

            if(isDark: boolean) {
                option: {
                    fill: {
                        type: 'gradient';
                        gradient: {
                            type: 'vertical';
                            shadeIntensity: 1;
                            inverseColors: !1;
                            opacityFrom: 0.3;
                            opacityTo: 0.05;
                            stops: [100, 100];
                        }
                    }
                }
            },
        };
    });

    const followersSeries = ref([
        {
            data: [38, 60, 38, 52, 36, 40, 28],
        },
    ]);

    // referral
    const referral = computed(() => {
        const isDark: boolean = store.theme === 'dark' || store.isDarkMode ? true : false;
        return {
            chart: {
                height: 160,
                type: 'area',
                fontFamily: 'Nunito, sans-serif',
                sparkline: {
                    enabled: true,
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            colors: ['#e7515a'],
            grid: {
                padding: {
                    top: 5,
                },
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: (val: any) => {
                            return '';
                        },
                    },
                },
            },

            if(isDark: boolean) {
                option: {
                    fill: {
                        type: 'gradient';
                        gradient: {
                            type: 'vertical';
                            shadeIntensity: 1;
                            inverseColors: !1;
                            opacityFrom: 0.3;
                            opacityTo: 0.05;
                            stops: [100, 100];
                        }
                    }
                }
            },
        };
    });

    const referralSeries = ref([
        {
            data: [60, 28, 52, 38, 40, 36, 38],
        },
    ]);

    // engagement
    const engagement = computed(() => {
        const isDark: boolean = store.theme === 'dark' || store.isDarkMode ? true : false;
        return {
            chart: {
                height: 160,
                type: 'area',
                fontFamily: 'Nunito, sans-serif',
                sparkline: {
                    enabled: true,
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            colors: ['#1abc9c'],
            grid: {
                padding: {
                    top: 5,
                },
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: (val: any) => {
                            return '';
                        },
                    },
                },
            },

            if(isDark: boolean) {
                option: {
                    fill: {
                        type: 'gradient';
                        gradient: {
                            type: 'vertical';
                            shadeIntensity: 1;
                            inverseColors: !1;
                            opacityFrom: 0.3;
                            opacityTo: 0.05;
                            stops: [100, 100];
                        }
                    }
                }
            },
        };
    });

    const engagementSeries = ref([
        {
            name: 'Sales',
            data: [28, 50, 36, 60, 38, 52, 38],
        },
    ]);
</script>
